<template>
  <v-bottom-navigation
      style="z-index: 999;"
      :id="getNavDrawerId()"
      :background-color="'primary'"
      dark
      shift
      grow
      fixed
  >
    <v-btn @click="goTo('/')">
      <span>Start</span>
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <v-btn @click="goTo('/planner/todo')">
      <span>Aufgaben</span>
      <v-icon>mdi-format-list-checks</v-icon>
    </v-btn>

    <v-btn @click="goTo('/weddings')" >
      <span>Hochzeiten</span>
      <v-icon>mdi-ring</v-icon>
    </v-btn>

    <v-btn  @click="goTo('/kunden')" >
      <span>Kunden</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <v-btn @click="toggleMenu" :background-color="'primary'">
      <span>Mehr</span>
      <v-icon>mdi mdi-backburger</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
import {mapGetters} from "vuex";
import {date2String, error, msgObj} from "@/helper/helper";

export default {
  name: "Toolbar",
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('notification', {
      notifications: 'notificationsUnRead',
    }),
  },
  mounted() {
    this.$store.dispatch('notification/getNotifications', {
      uid: this.user.id,
      onlyUnread: true
    }).catch((err) => {
      this.message = error(err)
    })
  },
  data() {
    return {
      menu: false,
      menu2: false,
      message: msgObj()
    }
  },
  methods: {
    getNavDrawerId(){
      if(window.innerWidth < 1264){
        return 'navDrawer'
      }else{
        return ''
      }
    },
    goTo(route) {
      this.$router.push(route)
    },
    logout() {
      this.$store.dispatch('auth/signedOut', {}).then(() => {
        console.log('User ausgeloggt!')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    toggleMenu(){
      this.$emit('toggleMenu', {})
    },
    getStringDate(date) {
      return date2String(date)
    },
  }
}
</script>

<style scoped>
.welcome {
  padding-left: 275px;
}

@media only screen and (max-width: 1280px) {
  .welcome {
    padding-left: 10px
  }
}
#app .v-bottom-navigation .v-btn {
  height: inherit !important;
  background-color: #6D7E6D !important;
}
</style>
